<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 130px">
          <a-input
              class="input"
              v-model="queryParams.lawyerUid"
              placeholder="律师ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.bizTitle"
              placeholder="业务类型"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
<!--        <a-button v-hasPermission="'qrcode:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--          新增-->
<!--        </a-button>-->
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="record.userInfo.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.userInfo.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.userInfo.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('lawOrderStatus', 'color')">{{
            record.status | dictName("lawOrderStatus")
          }}
        </a-tag>
      </template>
      <template slot="price" slot-scope="text, record">
        ￥{{ record.price }}/{{record.priceNumber}}{{record.priceUnit}}
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "LawOrderList",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "law/biz/listByAdmin",
      listMethod: "get",
    };
  },
  computed: {
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "律师ID",
          align: "center",
          width: 100,
          dataIndex: "lawyerUid",
        },
        {
          title: "律师名称",
          align: "center",
          width: 150,
          dataIndex: "lawyerInfo.username",
        },
        {
          title: "业务名称",
          align: "center",
          width: 200,
          dataIndex: "bizTitle",
        },
        {
          title: "价格",
          align: "center",
          width: 100,
          dataIndex: "price",
            scopedSlots: {customRender: "price"},
        },
        {
          title: "业务介绍",
          align: "center",
          width: 400,
          dataIndex: "bizDesc",
        },
        // {
        //   title: "状态",
        //   align: "center",
        //   width: 80,
        //   dataIndex: "status",
        //   scopedSlots: {customRender: "status"},
        // },
        {
          title: "上架时间",
          align: "center",
          width: 150,
          dataIndex: "createTime",
        },
        // {
        //   title: "说明",
        //   align: "center",
        //   width: 200,
        //   ellipsis: true,
        //   dataIndex: "remark"
        // },
        // {
        //   title: "操作",
        //   align: "center",
        //   dataIndex: "operation",
        //   fixed: "right",
        //   scopedSlots: {customRender: "operation"},
        // },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;
      that.$message.info("暂未实现");
      this.$confirm({
        title: "删除确认",
        content: "警告：刪除后将无法恢复！您确定要删除订单【" + record.orderNo + "】吗？！",
        centered: true,
        onOk() {
          that.$post("law/order/delete", {orderId:record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
